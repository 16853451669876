import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import List from "@material-ui/core/List";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import React from "react";
import { version } from "../../package.json";
import cpgLogo from "./Logo/Container-Port-White-Logotype.png";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
   root: {
      display: "flex",
   },
   drawer: {
      [theme.breakpoints.up("sm")]: {
         width: drawerWidth,
         flexShrink: 0,
      },
   },
   toolbar: theme.mixins.toolbar,
   drawerPaper: {
      width: drawerWidth,
      backgroundColor: "#010440",
   },
   content: {
      flexGrow: 1,
      padding: theme.spacing(3),
   },
   listItem: {
      backgroundColor: "#010440",
      color: "white",
      "&:hover": {
         backgroundColor: "#004B8D",
      },
   },

   listItemText: {
      color: "white",
      paddingLeft: "1rem",
   },

   listItemSelected: {
      backgroundColor: "white",
      opacity: "75%",
      "&:hover": {
         backgroundColor: "white",
      },
   },

   listItemTextSelected: {
      color: "black",
      paddingLeft: "1rem",
   },

   listItemIconSelected: {
      color: "black",
      display: "contents",
      width: "max-content",
   },

   listItemIcon: {
      color: "white",
      display: "contents",
      width: "max-content",
   },

   hrStyle: {
      borderColor: "whitesmoke",
      padding: "0px",
      margin: "0px",
   },
   imgStyle: {
      height: "67px",
      padding: "2%",
      marginTop: "5px",
   },
   dividerImg: {
      backgroundColor: "#B0BBC3",
      height: "1px",
      width: "85%",
      marginLeft: "20px",
   },
   divImg: {
      textAlign: "center",
   },
}));

function Sidebar(props) {
   const { container } = props;
   const classes = useStyles();
   const theme = useTheme();
   const [mobileOpen, setMobileOpen] = React.useState(false);

   const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
   };

   const drawer = (
      <div className={classes.toolbar}>
         <List>
            <div
               style={{
                  position: "fixed",
                  bottom: "10px",
                  backgroundColor: "#010440",
                  color: "white",
                  width: "240px",
                  textAlign: "center",
               }}
            >
               Version: {version}
            </div>
         </List>
      </div>
   );

   return (
      <div className={classes.root}>
         <CssBaseline />
         <nav className={classes.drawer} aria-label="mailbox folders">
            <Divider className={classes.hrStyle} />
            <Hidden smUp implementation="css">
               <Drawer
                  container={container}
                  variant="temporary"
                  anchor={theme.direction === "rtl" ? "right" : "left"}
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  classes={{
                     paper: classes.drawerPaper,
                  }}
                  ModalProps={{
                     keepMounted: true,
                  }}
               >
                  {drawer}
               </Drawer>
            </Hidden>
            <Hidden xsDown implementation="css">
               <Drawer
                  classes={{
                     paper: classes.drawerPaper,
                  }}
                  variant="permanent"
                  open
               >
                  <div className={classes.divImg}>
                     <img className={classes.imgStyle} src={cpgLogo}></img>
                  </div>

                  <Divider className={classes.dividerImg} />

                  {drawer}
               </Drawer>
            </Hidden>
         </nav>
      </div>
   );
}

export default Sidebar;
