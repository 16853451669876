import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useState } from "react";
import "../styles/App.css";
import "../styles/truck.css";
// Redux
// Components
import cpgLogo from "./Logo/ContainerPort-Full-Color-Logo-RGB-300dpi.png";
import Sidebar from "./Sidebar";
import Header from "./header/Header";

const useStyles = makeStyles((theme) => ({
   contStyle: {
      margin: "0rem",
   },
   mainControl: {
      [theme.breakpoints.up("sm")]: {},
      position: "fixed",
      top: "10%",
      left: "45%",
   },
   backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
   },
}));

function App() {
   const classes = useStyles();

   let [state, setState] = useState({
      selectedComponent: "ratemanagementsearch",
   });

   //need the user state to fill in for the settings on the screen
   return (
      <>
         <Sidebar setParentState={setState} parentState={state} />
         <Header content={<p> Welcome!</p>} setParentState={setState} />
         <Grid item xs>
            <div className={classes.mainControl}>
               <div className="maincontrolinner">
                  <img style={{ height: "200px", width: "500px" }} src={cpgLogo} alt=""></img>
                  <br />
                  <br />
                  <br />
                  <div style={{ border: "3px solid #002D72", borderRadius: "10px", width: "500px" }}>
                     <div style={{ margin: "5px" }}>
                        Due to current market conditions CPG wants to ensure our customers receive competitive pricing
                        for your drayage needs. Please reach out to{" "}
                        <a href="mailto:quote@containerport.com">quote@containerport.com</a> for pricing. Thank you for
                        the opportunity to handle your freight.
                     </div>
                  </div>
               </div>
            </div>
         </Grid>
      </>
   );
}

export default App;
