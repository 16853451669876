import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import "../Logo/Container-Port-White-Logotype.png";

const useStyles = makeStyles((theme) => ({
   containerStyle: {
      [theme.breakpoints.up("sm")]: {
         width: "100%",
      },
      backgroundColor: "#010440",
      width: "100%",
      maxHeight: "78px",
   },
   title: {
      flexGrow: 1,
      textAlign: "center",
      color: "white",
   },
   menuButton: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.up("sm")]: {
         display: "none",
      },
   },
   headerButtons: {
      backgroundColor: "#010440",
      color: "white",
      paddingRight: "5rem",
      paddingLeft: "2rem",
   },
   headerDivider: {
      color: "#010440",
      height: "3.1rem",
   },
   imgStyle: {
      height: "67px",
      padding: "2%",
      marginTop: "5px",
   },
}));

function Header() {
   const classes = useStyles();

   return (
      <AppBar position="fixed" className={classes.containerStyle}>
         <Toolbar style={{ backgroundColor: "#010440" }}>
            <div>
               <a href="/">
                  <img
                     className={classes.imgStyle}
                     src={require("../Logo/Container-Port-White-Logotype.png")}
                     alt=""
                  ></img>
               </a>
            </div>
         </Toolbar>
      </AppBar>
   );
}

export default Header;
