import { CssBaseline } from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import React from "react";
import { render } from "react-dom";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import App from "./components/App";
import "./styles/index.css";

const theme = createTheme({
   palette: {
      primary: {
         main: "#002D72",
      },
      background: {
         default: "#f2f2f2",
      },
      text: {
         primary: "#6F6F6F",
      },
   },
   typography: {
      fontFamily: `"Helvetica", sans-serif`,
      fontSize: 14,
      fontWeightLight: 300,
      fontWeightRegular: 600,
      fontWeightMedium: 700,
   },
   overrides: {
      MuiInputBase: {
         input: {
            "&:-webkit-autofill": {
               //This is how we ignore the auto-filling styles from browsers like Chrome!!!!
               transitionDelay: "99999s",
               transitionProperty: "background-color, color",
            },
         },
      },
   },
});

render(
   <>
      <ThemeProvider theme={theme}>
         <CssBaseline />
         <Router>
            <Switch>
               <Route path="/" component={App} />
            </Switch>
         </Router>
      </ThemeProvider>
   </>,
   document.getElementById("root"),
);
